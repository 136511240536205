import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  // API url
  baseApiUrl = environment.apiUrlUpload;
  baseApiUrlCancel = environment.apiUrlUploadCancel;

  constructor(private http:HttpClient) { }
  // Returns an observable
  upload(file,item):Observable<any> {
  
      // Create form data
      const formData = new FormData(); 
      // Store form name as "file" with file data
      formData.append("file", file, file.name);
      formData.append("api_token_key", 'a3393d48c839d8fbe212457cddff720a');
      formData.append("depart_id",item.depart_id);
      formData.append("edoc_id",item.edoc_id);
      formData.append("main_id",item.main_id);
      formData.append("file_name",item.file_name);
      formData.append("tposition_id",item.tposition_id);
      formData.append("main_status",item.main_status);
      formData.append("main_type",item.main_type);
      formData.append("detail_id",item.detail_id);

      // Make http post request over api
      // with formData as req
      return this.http.post(this.baseApiUrl, formData)
  }

  // Returns an ยกเลิก แก้ไข
  upload_cancel(file,item, text_callblack):Observable<any> {
  
    // Create form data
    const formData = new FormData(); 
    // Store form name as "file" with file data
    formData.append("file", file, file.name);
    formData.append("api_token_key", 'a3393d48c839d8fbe212457cddff720a');
    formData.append("depart_id",item.depart_id);
    formData.append("edoc_id",item.edoc_id);
    formData.append("main_id",item.main_id);
    formData.append("file_name",item.file_name);
    formData.append("tposition_id",item.tposition_id);
    formData.append("main_status",item.main_status);
    formData.append("main_type",item.main_type);
    formData.append("detail_id",item.detail_id);
    formData.append("text_callblack",text_callblack);


    // Make http post request over api
    // with formData as req
    return this.http.post(this.baseApiUrlCancel, formData)
}

}
