import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { AccessProviders } from './providers/access-providers';
import { SearchPage } from './pages/search/search.page';
import { SearchDetailModalComponent } from './pages/search-detail-modal/search-detail-modal.component';
import { DetailModalComponent } from './pages/detail-modal/detail-modal.component';
import { SafePipe } from './safe.pipe';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';


@NgModule({
  declarations: [AppComponent, SearchDetailModalComponent,PdfViewerComponent, SafePipe, DetailModalComponent],
  entryComponents: [],
  imports: [BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule
  ],
  providers: [
    AccessProviders,
    { provide: LocationStrategy,  useClass:HashLocationStrategy}
  ],
  exports: [SearchDetailModalComponent, DetailModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
