import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, Event, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AlertController, NavController } from '@ionic/angular';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{
  constructor(private router: Router, private auth: AuthService, private alertCtrl: AlertController, navCtrl: NavController) { }
  navLogin:any;
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => {
        if (!user) {
          console.log(this.router.url);
          this.navLogin = 0;
          this.router.events.subscribe((event: NavigationStart) => {
            //console.log(event.url);
            //console.log(event instanceof NavigationStart);
            if(event.url === '/login') {
              //allow
              this.navLogin = true;
            }
          });
          if(this.navLogin) {// If the login page does not require notification
            this.alertCtrl.create({
              header: 'ไม่ได้รับอนุญาต',
              message: 'คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านั้น',
              buttons: ['OK']
            }).then(alert => alert.present());
          }
 
          this.router.navigateByUrl('/login',{ skipLocationChange: true });

          return false;

        } else {
          return true;
        }
      })
      
    )
  }
}