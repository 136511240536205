import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'details/:id/:uid',
    loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search-detail/:id/:uid',
    loadChildren: () => import('./pages/search-detail/search-detail.module').then( m => m.SearchDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'signed',
    loadChildren: () => import('./pages/signed/signed.module').then( m => m.SignedPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'signed-detail/:id/:uid',
    loadChildren: () => import('./pages/signed-detail/signed-detail.module').then( m => m.SignedDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'guidebook',
    loadChildren: () => import('./pages/guidebook/guidebook.module').then( m => m.GuidebookPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule)
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
