import { Component, Input, OnInit } from '@angular/core';
import { NavParams, ModalController, IonicModule } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastController, LoadingController, AlertController, NavController } from '@ionic/angular';
import { AccessProviders } from '../../providers/access-providers';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-search-detail-modal',
  templateUrl: './search-detail-modal.component.html',
})
export class SearchDetailModalComponent implements OnInit {

  @Input() pdfUrl: string;  // รับ URL ของ PDF

  id: any;
  uid_passport: any;
  docs_detail: any = [];
  doc_no: string = "";
  doc_date: string = "";
  edoc_type: string = "";
  headline: string = "";
  receiver: string = "";
  depart_submit: string = "";
  agenc_dept: string = "";
  sender_depart_id: string = "";
  sender_depart: string = "";
  feedback: string = "";
  docType: string = "";
  Secrets: string = "";
  Rapid: string = "";
  disabledButton: Boolean;
  dirName: string;
  dirPath: string;

  user = null;
  docTypeModal: any;

  fileUrlCap: SafeResourceUrl | undefined;
  pdfShow: boolean = true;

  constructor(
    private router: Router,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private accsPrvds: AccessProviders,
    private actRoute: ActivatedRoute,
    private storage: Storage,
    public navCtrl: NavController,
    private platform: Platform,
    private navParams: NavParams,
    private modalController: ModalController,
    private sanitizer: DomSanitizer
  ) { 

    // this.fileUrlCap = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeUrl);

  }

  ngOnInit() {
    // this.actRoute.params.subscribe((data: any)=>{
    //   //console.log(data);
    //   this.id = data.id;
    //   this.uid_passport = data.uid;

    //   if(this.id != 0){
    //     this.loadData();
    //     //this.ionViewDidEnter();
    //   }
    // });

    // Retrieve the passed parameters
    this.id = this.navParams.get('main_id');
    this.uid_passport = this.navParams.get('uid');
    console.log('id main: ', this.id);
    console.log('user id: ', this.uid_passport);

    if(this.id != 0){
      this.loadData();
      //this.ionViewDidEnter();
    }
  }

  ionViewDidEnter(){
    this.disabledButton = false;
    //this.docs_detail = [];
  }

  async loadData(){
    const loader = await this.loadingCtrl.create({
      message: 'Please wait ......',
    });
    loader.present();

    this.docs_detail = [];
    return new Promise(resolve => {
      let body = {
        aksi: 'load_single_data_signed',
        main_id: this.id,
        uid_passport: this.uid_passport
    }
        this.accsPrvds.postData(body, environment.apiProsesFile).subscribe((res:any)=>{
          //console.log(res);  
        loader.dismiss();
        this.doc_no = res.result.doc_no;
        this.doc_date = res.result.doc_date;
        this.edoc_type = res.result.edoc_type;
        this.headline = res.result.headline;
        this.receiver = res.result.receiver;
        this.depart_submit = res.result.depart_submit;
        this.agenc_dept = res.result.agenc_dept;
        this.sender_depart_id = res.result.sender_depart_id;
        this.sender_depart = res.result.sender_depart;
        this.docType = res.result.doc_type; //ประเภทการลงนาม/เกษียน
        this.Secrets = res.result.Secrets;
        this.Rapid = res.result.Rapid;
        
        for(let datas of res.result_detail){ // specail if you want to use infiniti scroll load data per limit
          this.docs_detail.push(datas);
        }
        //console.log(this.docType);
        resolve(true);
        
      });

    });
  }

  backTo(a) {
    //this.navCtrl.navigateRoot('/' + a);
    this.router.navigateByUrl('/'+ a ,{ skipLocationChange: true });

  }

  async presentToast(a){
    const toast = await this.toastCtrl.create({
      message: a,
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }

  async presentAlertOpen(a, b) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Close',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
            // action
          }
        }, {
          text: 'Try Again',
          handler: () => {
            this.openDocAction(b);
          }
        }
      ]
    });

    await alert.present();
  }

  //เซนหนังสือ หรือเปิดดูหนังสือ
  async openDocAction(doc) {
    
    //alert('open doc singed...');

    const loader = await this.loadingCtrl.create({
      message: 'Please wait ......',
    });
    loader.present();

    return new Promise(resolve => {
      let body = {
        aksi: 'proses_opendoc_signed',
        detail_id: doc.detail_id
      }

      this.accsPrvds.postData(body, environment.apiProsesFile).subscribe((res:any)=>{
        //console.log(res);
        if(res.success == true){
          loader.dismiss();

          // if(doc.read_status == '0' || doc.read_status == '1'){
          //   this.downloadPdf(doc);
          //   this.ngOnInit();
          // }
          // else if(doc.read_status == '1') {
          //    this.preViewFile(doc.file_name);
          // }

          //this.downloadPdf(doc);
          this.downloadBrowserAfterSign(doc.file_path);
          this.ngOnInit();

        }else{
          loader.dismiss();
          this.presentAlertOpen(res.msg, doc);
        }
      },(err)=>{
        loader.dismiss();
        this.presentAlertOpen('ไม่สามารถเปิดไฟล์หนังสือได้ กรุณาตรวจสอบสัญญาณอินเตอร์เน็ตและลองใหม่อีกคร้ัง', doc);
      });
    });

  }

  downloadBrowserAfterSign(file_path) {
    //console.log('download Browser', file_path);
    var url = environment.apiUrlLink + file_path;
    var windowref = window.open(url, '_blank', 'location=no,closebuttoncaption=Cerrar,toolbar=yes,enableViewportScale=yes');

  }

  dismissModal() {
    this.modalController.dismiss(); // Close the modal
  }

  selectedDoc: any; // ใช้สำหรับเก็บเอกสารที่เลือก

  viewPdf(doc:any) {
    let doc_path = environment.apiUrlLink + doc.file_path;
    //doc_path = 'e-doc.rmutsv.ac.th/document/approv_signed/D0026/2022/DOC236_a58324ef1893919314dd0fc01f8b1692_edoc_2022-01-01-1640972852-M218531.pdf';
    console.log('file :', doc_path);
    this.selectedDoc = doc_path; // ตั้งค่า selectedDoc เป็นเอกสารที่เลือก
    this.pdfShow = false;
    this.fileUrlCap = this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedDoc);
  }

}
