import { Component, OnInit } from '@angular/core';
import { ToastController, LoadingController, AlertController, NavController, Platform } from '@ionic/angular';
import { NavigationEnd, NavigationError, NavigationStart, Router, Event, ActivatedRoute } from '@angular/router';
import { AccessProviders } from './providers/access-providers';
import { Storage } from '@ionic/storage';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { TabCountService } from './services/tab-count.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit  {
  public appPages = [
    { title: 'เกษียน/ลงนาม', url: '/home', icon: 'pencil', notit: '1' },
    { title: 'เกษียน/ลงนามแล้ว', url: '/signed', icon: 'paper-plane', notit: '2' },
    { title: 'ค้นหา', url: '/search', icon: 'search', notit: '2' },
    { title: 'การใช้งาน', url: '/guidebook', icon: 'information', notit: '2' },
    { title: 'สถิติการลดกระดาษ', url: '/report', icon: 'information', notit: '2' }
    
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  signerName: string;
  userPassport: string;
  userMail: string;
  user = null;
  countApprov: number = 0;
  id: number;
  uid_passport: string;

  constructor(
    public navCtrl: NavController,
    private auth: AuthService,
    private router: Router,
    private accsPrvds: AccessProviders,
    private actRoute: ActivatedRoute,
    private tabCountService: TabCountService

  ) {
    //this.initializeApp();

    // this.router.events.subscribe((event: Event) => {
    //     if (event instanceof NavigationStart) {
    //         // Show loading indicator
    //     }

    //     if (event instanceof NavigationEnd) {
    //         // Hide loading indicator
    //     }

    //     if (event instanceof NavigationError) {
    //         // Hide loading indicator

    //         // Present error to user
    //         console.log(event.error);
    //     }
    // });

    //this.ionViewDidEnter();
    //this.loadData();
  }

  ngOnInit() {
    this.tabCountService.currentCount.subscribe(count => {
      this.countApprov = count;
      //console.log(this.countApprov);
    });
  }

  initializeApp() {
    //this.user = this.auth.getUser();
    //console.log('user: ',this.user);

    // this.storage.get('user').then((res)=>{
    //   if(res == null){
    //     this.navCtrl.navigateRoot('/login');
    //   }else{
    //     console.log('test',res);
    //     //this.navCtrl.navigateRoot('/tabs/home');
    //   }
    // });
  }

  ionViewDidEnter(){
    this.user = this.auth.getUser();
    console.log('user: ', this.user);
    console.log('Home: ');
    
  }
  
  async loadData(){
 
    return new Promise(resolve => {
      let body = {
        aksi: 'load_single_data',
        main_id: this.id,
        uid_passport: this.uid_passport
      }

      this.accsPrvds.postData(body, environment.apiProsesFile).subscribe((res:any)=>{

        console.log('load data: ',res);

        this.user = this.auth.getUser();

        console.log('user login: ',this.user);

        // this.signerName = this.user.data.signerName;
        // this.userPassport = this.user.data.username;
        //loader.dismiss();

        
      });

    });
  }

  

}
