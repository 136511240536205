import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';

import { environment } from '../../environments/environment';

@Injectable()
export class AccessProviders {
    // url backend api json from enviroments
    
    //server: string = 'https://e-doc.rmutsv.ac.th/api/apps/';
    //server: string = 'https://e-doc-new.rmutsv.ac.th/api/apps/';

    constructor(
        public http: HttpClient
    ){}

    postData(body, file){
        let headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=UTF-8'
        });
        let options = {
            headers: headers
        }
        return this.http.post(environment.apiUrlServer + file, JSON.stringify(body), options)
        .timeout(59000) // 59 sec timeout
        .map(res => res);
    }
    
    uploadFile(formData) {
        return this.http.post(environment.apiUrlUpload, formData);
      }
}
