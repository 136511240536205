// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://e-doc.rmutsv.ac.th';
export const environment = {
  production: false,
  apiUrlLogin: URL + '/api/apps',
  apiUrlPHP: URL + '/api/apps/',
  apiUrlServer: URL + '/api/apps/',
  apiUrlLink: URL + '/document',
  apiUrlUpload: URL + '/api/apps/upload_webapp.php',
  apiUrlUploadCancel: URL + '/api/apps/upload_webapp_cancel.php',
  apiProsesFile: 'proses_api_webapp.php',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
