import { environment } from '../../../environments/environment';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastController, LoadingController, AlertController, NavController, NavParams, ModalController } from '@ionic/angular';
import { AccessProviders } from '../../providers/access-providers';
import { Platform, ActionSheetController } from '@ionic/angular';
import { UploadService } from '../../services/upload.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-modal',
  templateUrl: './detail-modal.component.html',
  styleUrls: ['./detail-modal.component.scss'],
})
export class DetailModalComponent implements OnInit {
  @Output() uploadSuccess: EventEmitter<any> = new EventEmitter();

  id: number;
  uid_passport: string;
  docs_list: any = [];
  datas: any = [];

  signerName: string;
  edoc_type: string = "";
  doc_no: string = "";
  doc_date: string = "";

  headline: string = "";
  receiver: string = "";
  depart_submit: string = "";
  agenc_dept: string = "";
  sender_depart_id: string = "";
  sender_depart: string = "";
  feedback: string = "";
  mainType: string = "";

  docType: string = "";
  Secrets: string = "";
  Rapid: string = "";

  numApprov: number = 22;


  disabledButton: boolean;

  dirName: string;
  dirPath: string;
  //loading: boolean;
  title: string;

  textSendback: string = '';
  sendback: any = 0; //การส่งกลับหรือยกเลิก 0=ไม่ส่งกลับ 1=ส่งกลับ
  uploadDetailId: any;

  // Variable to store shortLink from api response
  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file: File = null; // Variable to store file
  fileUpload: File = null;

  uploadconfirm: boolean = false;

  constructor(
    private router: Router,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private accsPrvds: AccessProviders,
    private actRoute: ActivatedRoute,
    public navCtrl: NavController,
    private platform: Platform,
    public actionSheetCtrl: ActionSheetController,
    private uploadFileService: UploadService,
    private sanitizer: DomSanitizer,
    private navParams: NavParams,
    private modalController: ModalController,

  ) { }

  ngOnInit() {
    // Retrieve the passed parameters
    this.id = this.navParams.get('main_id');
    this.uid_passport = this.navParams.get('uid');
    //console.log('id main: ', this.id);
    //console.log('user id: ', this.uid_passport);

    if (this.id != 0) {
      this.loadData();
      //this.ionViewDidEnter();
      this.docs_list = [];
      this.datas = [];
      this.uploadDetailId = 0;
      this.uploadconfirm = false;
    }

  }

  // แสดงผลเพิ่มเติม
  isExpanded: boolean = false;
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  myBackButton() {
    this.navCtrl.navigateRoot('/tabs/home');
  }

  changeRoute() {
    this.router.navigate(['/home']);
    //this.router.navigate(['/tabs', { id: 'heroId' }]);
    //this.router.navigate(['items'], { relativeTo: this.route });
  }

  ionViewDidEnter() {
    this.disabledButton = false;
    this.loading = false;
  }

  async loadData() {
    const loader = await this.loadingCtrl.create({
      message: 'Please wait ......',
    });
    loader.present();
    return new Promise(resolve => {
      let body = {
        aksi: 'load_single_data',
        main_id: this.id,
        uid_passport: this.uid_passport
      }

      this.accsPrvds.postData(body, environment.apiProsesFile).subscribe((res: any) => {

        //console.log('load data: ', res);
        loader.dismiss();

        this.docs_list = [];
        this.datas = [];

        this.doc_date = res.result.doc_date;
        this.doc_no = res.result.doc_no;
        this.mainType = res.result.main_type;
        this.edoc_type = res.result.edoc_type;

        this.headline = res.result.headline;
        this.receiver = res.result.receiver;
        this.depart_submit = res.result.depart_submit;
        this.agenc_dept = res.result.agenc_dept;
        this.sender_depart_id = res.result.sender_depart_id;
        this.sender_depart = res.result.sender_depart;

        this.docType = res.result.doc_type;
        this.Secrets = res.result.Secrets;
        this.Rapid = res.result.Rapid;

        for (let datas of res.result_detail) { // specail if you want to use infiniti scroll load data per limit
          this.docs_list.push(datas);
        }

        // console.log('docs list: ', this.docs_list);
        resolve(true);

      });

    });
  }

  backTo(a) {
    //this.navCtrl.navigateRoot('/tabs/' + a);
    this.router.navigateByUrl('/' + a, { skipLocationChange: true });

    //alert(a);
  }

  //alert กรณีส่งกลับ
  async presentAlertSendฺBackCancel(a, doc) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      inputs: [
        // multiline input.
        {
          name: 'textSendback',
          id: 'textSendback',
          type: 'textarea',
          placeholder: 'เหตุผลที่ต้องการยกเลิก'
        }
      ],

      buttons: [
        {
          text: 'ปิด',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            // action
          }
        }, {
          text: 'ยืนยัน',
          handler: (data) => {
            //this.sendDocAction(b);
            this.sendbackCancel(doc, data.textSendback);

          }
        }
      ]
    });

    await alert.present();
  }

  async presentToast(a) {
    const toast = await this.toastCtrl.create({
      message: a,
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }

  async presentAlert(h, m) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: h,
      subHeader: '',
      message: m,
      buttons: ['ตกลง']
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  // send back to สารบรรณ
  async sendbackCancel(doc, text_send) {
    console.log('text send back: ', text_send);
    const loader = await this.loadingCtrl.create({
      message: 'Please wait ......',
    });
    loader.present();

    return new Promise(reslolve => {
      let body = {
        aksi: 'proses_sendback_cancel',
        main_id: doc.main_id,
        detail_id: doc.detail_id,
        tposition_id: doc.tposition_id,
        file_name: doc.file_name,
        file_path: doc.file_path,
        text_sendback: text_send
      }

      this.accsPrvds.postData(body, environment.apiProsesFile).subscribe((res: any) => {
        //console.log('openDoc data: ',res);
        if (res.success == true) {
          this.disabledButton = false; //disabled button ส่ง
          //this.loading = false; // close loading...
          this.presentToast('ยกเลิกสำเร็จ...');
           // กลับไปยังหน้าหลัก
           this.returnToMain(doc);

          loader.dismiss();
        } else {
          loader.dismiss();
          //this.presentAlertOpen(res.msg, doc);
          this.presentAlert('ดำเนินการไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง หรือตรวจสอบสัญญานอินเทอร์เน็ตของท่าน');
        }
      }, (err) => {
        loader.dismiss();
        this.presentAlert('ดำเนินการไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง หรือตรวจสอบสัญญานอินเทอร์เน็ตของท่าน');
      });

    });
  }

  // send back to สารบรรณ
  async sendbackNotSign(doc, text_send) {
    //console.log('text send back: ', text_send);
    const loader = await this.loadingCtrl.create({
      message: 'Please wait ......',
    });
    loader.present();

    return new Promise(reslolve => {
      let body = {
        aksi: 'proses_sendback_notsign',
        depart_id: doc.depart_id,
        main_id: doc.main_id,
        detail_id: doc.detail_id,
        tposition_id: doc.tposition_id,
        file_name: doc.file_name,
        file_path: doc.file_path,
        text_sendback: text_send,
        main_status: doc.main_status,
        main_type: doc.main_type
      }

      this.accsPrvds.postData(body, environment.apiProsesFile).subscribe((res: any) => {
        //console.log('openDoc data: ',res);
        if (res.success == true) {
          this.disabledButton = false; //disabled button ส่ง
          //this.loading = false; // close loading...
          this.presentToast('ดำเนินการเสร็จเรียบร้อย...');
          // กลับไปยังหน้าหลัก
          this.returnToMain(doc);
          loader.dismiss();
        } else {
          loader.dismiss();
          //this.presentAlertOpen(res.msg, doc);
          this.presentAlert('ดำเนินการไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง หรือตรวจสอบสัญญานอินเทอร์เน็ตของท่าน');
        }



      }, (err) => {
        loader.dismiss();
        this.presentAlert('ดำเนินการไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง หรือตรวจสอบสัญญานอินเทอร์เน็ตของท่าน');
      });

    });
  }

  downloadBrowser(item) {
    //console.log('download Browser', item);
    var url = environment.apiUrlLink + item.file_path;
    var windowref = window.open(url, '_blank', 'location=no,closebuttoncaption=Cerrar,toolbar=yes,enableViewportScale=yes');
  }

  downloadBrowserAfterSign(file_path) {
    //console.log('download Browser', file_path);
    var url = environment.apiUrlLink + file_path;
    var windowref = window.open(url, '_blank', 'location=no,closebuttoncaption=Cerrar,toolbar=yes,enableViewportScale=yes');
  }

  loadImageFromDevice(event) {

    const file = event.target.files[0];

    //console.log(file);

    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = () => {

      // get the blob of the image:
      let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);

      // create blobURL, such that we could use it in an image element:
      let blobURL: string = URL.createObjectURL(blob);

    };

    reader.onerror = (error) => {

      //handle errors

    };
  };

  // On file Select
  onChange(event, detail_id) {
    this.file = event.target.files[0];
    //console.log(this.file);
    this.uploadconfirm = true;
    this.uploadDetailId = detail_id;
  }

  cancelUpload() {
    var inputValue = (<HTMLInputElement>document.getElementById("file-input")).value = null;
    this.uploadconfirm = false;
  }

  // OnClick of button Upload
  onUpload(doc) {
    this.loading = !this.loading;
    //console.log(this.file);
    this.uploadFileService.upload(this.file, doc).subscribe(
      (event: any) => {

        if (typeof (event) === 'object') {
          //console.log('file upload process : ',event);
          // Short link via api response
          this.shortLink = event.file_path;

          //console.log('shortLink: ',this.shortLink);
          this.disabledButton = false; //disabled button ส่ง
          this.loading = false; // close loading...

          this.presentToast('ส่งไฟล์สำเร็จ...');
          //ตรวจสอบว่ามีรายการที่ยังไม่เซ็นอีกหรือไม่
          this.ngOnInit();
        }
      }
    );
  }

  returnToMain(docs: any) {
    //console.log('docs: ',docs);
    // ลบข้อมูลใน docs ตาม main_id ที่ได้กลับมาจาก modal
    this.docs_list = this.docs_list.filter(doc => doc.detail_id !== docs.detail_id);
    //console.log('after remove docs_list : ', this.docs_list);
    // ตรวจสอบว่ามีข้อมูลที่ sign_status == 0 หรือไม่ใน this.docs_list
    //const hasUnsignedDocs = this.docs_list.some(item => item.sign_status === 0);
    if (this.docs_list.length == 0) {
      // ถ้าไม่มี sign_status == 0 ส่งข้อมูลกลับไปเพื่อลบใน component A
      // console.log('hasUnsignedDocs: ', hasUnsignedDocs);
      this.modalController.dismiss(docs.main_id);
      this.dismissModal();
    } else {
      // ถ้ามี sign_status == 0 ไม่ทำการลบใน component A
      this.presentToast('ยังมีเอกสารที่ไม่ได้ลงนาม');
      this.disabledButton = false; //disabled button ส่ง
      this.loading = false;

      this.uploadDetailId = 0;
      this.uploadconfirm = false;

    }
  }

  onUploadNew(docs) {
    this.loading = !this.loading;

    this.uploadFileService.upload(this.file, docs).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          this.shortLink = event.file_path;
          this.presentToast('ส่งไฟล์สำเร็จ...');
          // กลับไปยังหน้าหลัก
          this.returnToMain(docs);
        }
      },
      (error) => {
        console.error('Upload error:', error);
        this.presentToast('การอัปโหลดล้มเหลว...');
        this.loading = false;
      }
    );
  }


  // OnClick of button Upload แก้ไข/ยกเลิก
  onUploadCancel(docs, text_callblack) {
    this.loading = !this.loading;
    //console.log(this.file);
    this.uploadFileService.upload_cancel(this.file, docs, text_callblack).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          //console.log('file upload process : ',event);
          // Short link via api response
          //this.shortLink = event.file_path;
          this.presentToast('ส่งไฟล์สำเร็จ...');
          // กลับไปยังหน้าหลัก
          this.returnToMain(docs);
        }
      }
    );
  }

  async confirmReturn(a, doc) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      inputs: [
        // multiline input.
        {
          name: 'textSendback',
          id: 'textSendback',
          type: 'textarea',
          placeholder: 'หมายเหตุ (ระบุ หรือไม่ก็ได้)'
        }
      ],
      buttons: [
        {
          text: 'ปิด',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
            // action
          }
        }, {
          text: 'ยืนยันการส่ง',
          handler: (data) => {
            //this.sendDocAction(b);
            this.sendbackNotSign(doc, data.textSendback);

          }
        }
      ]
    });

    await alert.present();
  }


  async confirmReturnCancel(a, doc) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      inputs: [
        // multiline input.
        {
          name: 'textSendback',
          id: 'textSendback',
          type: 'textarea',
          placeholder: 'หมายเหตุ (ระบุ หรือไม่ก็ได้)'
        }
      ],
      buttons: [
        {
          text: 'ปิด',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
            // action
          }
        }, {
          text: 'ยืนยันการส่ง',
          handler: (data) => {
            //this.sendDocAction(b);
            this.onUploadCancel(doc, data.textSendback);

          }
        }
      ]
    });

    await alert.present();
  }

  async openMenu(doc) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'เลือก',
      cssClass: 'action-sheets-basic-page',
      buttons: [

        {
          text: 'ส่ง (ไม่มีการเกษียณ หรือลงนามหนังสือ)',
          role: 'destructive',
          icon: !this.platform.is('ios') ? 'send-outline' : null,
          handler: () => {
            //console.log('ส่ง ไม่มีการเกษียน หรือลงนาม');
            this.confirmReturn('ยืนยันการส่งหนังสือแบบไม่เกษียน หรือลงนาม', doc);

          }
        },
        {
          text: 'ยกเลิก (ต้องการแก้ไขหนังสือ หรือมีข้อผิดพลาด)',
          role: 'destructive',
          icon: !this.platform.is('ios') ? 'send-outline' : null,
          handler: () => {
            //console.log('ส่งกลับหรือยกเลิก clicked');
            this.presentAlertSendฺBackCancel('ยืนยันการยกเลืก หรือส่งคืนเพื่อแก้ไขหนังสือ', doc);
          }
        },
        // {
        //   text: 'Delete',
        //   role: 'destructive',
        //   icon: !this.platform.is('ios') ? 'trash' : null,
        //   handler: () => {
        //     console.log('Delete clicked');
        //   }
        // },
        // {
        //   text: 'Share',
        //   icon: !this.platform.is('ios') ? 'share' : null,
        //   handler: () => {
        //     console.log('Share clicked');
        //   }
        // },
        // {
        //   text: 'Play',
        //   icon: !this.platform.is('ios') ? 'arrow-dropright-circle' : null,
        //   handler: () => {
        //     console.log('Play clicked');
        //   }
        // },
        // {
        //   text: 'Favorite',
        //   icon: !this.platform.is('ios') ? 'heart-outline' : null,
        //   handler: () => {
        //     console.log('Favorite clicked');
        //   }
        // },
        // {
        //   text: 'Cancel',
        //   role: 'cancel', // will always sort to be on the bottom
        //   icon: !this.platform.is('ios') ? 'close' : null,
        //   handler: () => {
        //     console.log('Cancel clicked');
        //   }
        // }
      ]
    });
    actionSheet.present();
  }

  async presentLoading() {
    const loading = await this.loadingCtrl.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 1000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    //console.log('Loading dismissed!', role);
  }

  async presentLoadingWithOptions() {
    const loading = await this.loadingCtrl.create({
      cssClass: 'my-custom-class',
      spinner: null,
      duration: 5000,
      message: 'Click the backdrop to dismiss early...',
      translucent: true,
      backdropDismiss: true
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    //console.log('Loading dismissed with role:', role);
  }



  async transferAlert(a) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      buttons: [
        {
          text: 'ปิด',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
            // action
          }
        }, {
          text: 'ลองใหม่อีกครั้ง',
          handler: () => {
            //this.transferFile(b);
          }
        }
      ]
    });

    await alert.present();
  }

  dismissModal() {
    this.modalController.dismiss(); // Close the modal
  }

}
